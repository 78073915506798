@use '../../styles/mixins' as mixins;

.cta-grid-item-module {
    background: var(--wp--preset--color--bnpp-green-tint-10);
    padding: var(--wp--preset--spacing--60);
    color: var(--wp--preset--color--bnpp-black);
    text-decoration: none;
    position: relative;

    h3 {
        font-size: var(--wp--preset--font-size--heading-xs);

        &:not(h3:has(+ *)) {
            margin: 0;
        }
    }

    p {
        font-weight: 300;
    }
    
}

a.cta-grid-item-module {

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        transition: 300ms;
    }

    &:hover {

        &::after {
            opacity: 1;
        }

    }
    
}